<template>
  <base-page
      :title="$t('accountPermissionManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findAccountPermissionList"
        :defaultParams="params"
        :headers="{product_version: 'ASSEMBLY_INSTRUCTION'}"
        :columns="columns"
        v-model="tableRow"
    >
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    let _that = this
    return {
      importTemplate: `/template/${lang}/customer.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('accountPermissionManage.nameOrAccount'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        needFullAddress: 'YES',
      },
      columns: [
        // lang: 姓名
        {
          label: this.$t('accountPermissionManage.name'),
          prop: 'name',
        },
        // lang:部门
        {
          label: this.$t('accountPermissionManage.department'),
          prop: 'group',
          callback(row) {
            return row.group.name
          },
        },
        // lang:账号
        {
          label: this.$t('accountPermissionManage.account'),
          prop: 'accountId',
        },
        // lang:产品密钥
        {
          label: this.$t('accountPermissionManage.productKey'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:编辑管理中心
        {
          label: this.$t('accountPermissionManage.webEditProductKey'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'WEB_EDIT_PRODUCT_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang: 任务可见范围
        {
          label: this.$t('accountPermissionManage.taskVisible'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.taskVisibleType === 'PART') {
              if(row.accountPermission.taskGroupVisibleSet !== null) {
                row.accountPermission.taskGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.taskAccountVisibleSet !== null) {
                row.accountPermission.taskAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.taskVisibleType === 'ALL' || row.accountPermission.taskVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          },
        },
        // lang: 任务派发范围
        {
          label: this.$t('accountPermissionManage.taskDistributeVisible'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.taskDistributeVisibleType === 'PART') {
              if(row.accountPermission.taskDistributeGroupVisibleSet !== null) {
                row.accountPermission.taskDistributeGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.taskDistributeAccountVisibleSet !== null) {
                row.accountPermission.taskDistributeAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.taskDistributeVisibleType === 'ALL' || row.accountPermission.taskDistributeVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          },
        },
        // lang: 文件对外分享范围
        {
          label: this.$t('accountPermissionManage.fileShareVisible'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.fileShareVisibleType === 'PART') {
              if(row.accountPermission.fileShareGroupVisibleSet !== null) {
                row.accountPermission.fileShareGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.fileShareAccountVisibleSet !== null) {
                row.accountPermission.fileShareAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.fileShareVisibleType === 'ALL' || row.accountPermission.fileShareVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          },
        },
        // lang: 监控可见范围
        {
          label: this.$t('accountPermissionManage.monitoringVisible'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.monitoringVisibleType === 'PART') {
              if(row.accountPermission.monitoringGroupVisibleSet !== null) {
                row.accountPermission.monitoringGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.monitoringAccountVisibleSet !== null) {
                row.accountPermission.monitoringAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.monitoringVisibleType === 'ALL' || row.accountPermission.monitoringVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          },
        },
        // lang: 报告可见范围
        {
          label: this.$t('accountPermissionManage.reportVisible'),
          prop: 'accountPermission',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.reportVisibleType === 'PART') {
              if(row.accountPermission.reportGroupVisibleSet !== null) {
                row.accountPermission.reportGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.reportAccountVisibleSet !== null) {
                row.accountPermission.reportAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.reportVisibleType === 'ALL' || row.accountPermission.reportVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          }
        },
        // lang: 数据分析范围
        {
          label: this.$t('accountPermissionManage.dataAnalysisVisible'),
          prop: 'canAddOutsideUserAccount',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.dataAnalysisVisibleType === 'PART') {
              if(row.accountPermission.dataAnalysisGroupVisibleSet !== null) {
                row.accountPermission.dataAnalysisGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.dataAnalysisAccountVisibleSet !== null) {
                row.accountPermission.dataAnalysisAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.dataAnalysisVisibleType === 'ALL' || row.accountPermission.dataAnalysisVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          }
        },
        // lang: 数据接口管理范围
        {
          label: this.$t('accountPermissionManage.dataApiVisible'),
          prop: 'canAddOutsideUserAccount',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.dataApiVisibleType === 'PART') {
              if(row.accountPermission.dataApiGroupVisibleSet !== null) {
                row.accountPermission.dataApiGroupVisibleSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.dataApiAccountVisibleSet !== null) {
                row.accountPermission.dataApiAccountVisibleSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.dataApiVisibleType === 'ALL' || row.accountPermission.dataApiVisibleType === null) {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          }
        },
      ],
      // 表单页
      showDetail: false,
      // 导入
      showDialogImport: false,
    }
  },
  mounted() {
    // const language = this.$util.getLocalStorage('lang')
    // this.dictFindTree('ADDRESS', language)
    // this.userAccountGroupFindTree()
  },
  methods: {
    // userAccountGroupFindTree() {
    //   this.$ajax({
    //     url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountGroupFindTree,
    //     data: {
    //       companyId: this.$store.getters.company.id,
    //       scope: 'COMPANY',
    //     },
    //   }).then((data) => {
    //     this.config[1].tagProps.options = data
    //   })
    // },
    // 查询字典
    // dictFindTree(dictType, language) {
    //   this.$ajax({
    //     url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
    //     data: {
    //       dictType,
    //       language,
    //     },
    //   }).then((data) => {
    //     this.config[1].tagProps.options = data || []
    //   })
    // },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.addressId) formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'permissionSetting':
          this.showDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>
